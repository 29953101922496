import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tickets--customer-details-by-phone"
export default class extends Controller {
  static targets = ["errorContainer", "consumerId", "phone"];
  static values = {
    blankPhoneMessage: String,
    noDataMessage: String,
    errorFetchingDataMessage: String
  };

  connect() {
    this.debouncedFetchCustomerDetails = this.#debounce(this.#fetchCustomerDetails);
  }

  fetchCustomerByPhone(e) {
    e.preventDefault();

    const phone = this.phoneTarget.value;
    if (phone) {
      this.#hideError();
      this.debouncedFetchCustomerDetails(phone);
    } else {
      this.#updateError(this.blankPhoneMessageValue);
      this.#clearCustomerId();
    }
  }

  #fetchCustomerDetails(phone) {
    fetch(`/search/consumer_details/by_phone_number/${phone}`)
      .then(response => response.json())
      .then(data => {
        if (data.consumer_id) {
          this.#populateCustomerDetails(data.consumer_id);
          this.#hideError();
          this.#setValidClass();
        } else {
          this.#updateError(this.noDataMessageValue);
          this.#clearCustomerId();
        }
      })
      .catch(error => {
        console.error(error);
        this.#updateError(this.errorFetchingDataMessageValue);
        this.#clearCustomerId();
      });
  }

  #populateCustomerDetails(consumerId) {
    this.consumerIdTarget.value = consumerId;
  }

  #clearCustomerId() {
    this.consumerIdTarget.value = "";
  }

  #updateError(message) {
    this.errorContainerTarget.innerHTML = message;
    this.errorContainerTarget.classList.add('d-block');
    this.phoneTarget.classList.remove('is-valid');
    this.phoneTarget.classList.add('is-invalid');
  }

  #hideError() {
    this.errorContainerTarget.innerHTML = "";
    this.errorContainerTarget.classList.remove('d-block');
  }

  #setValidClass() {
    this.phoneTarget.classList.remove('is-invalid');
    this.phoneTarget.classList.add('is-valid');
  }

  #debounce(func, delay = 350) {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => func.apply(this, args), delay);
    };
  }
}
