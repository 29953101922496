import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tickets--customer-details"
export default class extends Controller {
  static targets = ["errorContainer", "customerPhone", "customerEmail"];
  static values = {
    consumerIdIdentifier: String,
    invalidConsumerIdMessage: String,
    noDataMessage: String,
    errorFetchingDataMessage: String
  };

  fetchCustomerDetails(e) {
    e.preventDefault();

    const buttonHelper = this.#buttonHelper(e.target);
    buttonHelper.showLoader();

    const consumerId = document.querySelector(this.consumerIdIdentifierValue).value;
    if (consumerId) {
      this.#hideError();
      this.#fetchCustomerDetails(consumerId, buttonHelper);
    } else {
      this.#updateError(this.invalidConsumerIdMessageValue);
      buttonHelper.enableButton();
    }
  }

  #fetchCustomerDetails(consumerId, buttonHelper) {
    fetch(`/search/consumer_details/by_consumer_id/${consumerId}`)
      .then(response => response.json())
      .then(data => {
        if (data.consumer && data.consumer.consumer_id) {
          this.#populateCustomerDetails(data.consumer);
          this.#hideError();
        } else {
          this.#updateError(this.noDataMessageValue);
          this.#clearCustomerDetails();
        }
      })
      .catch(error => {
        console.error(error);
        this.#updateError(this.errorFetchingDataMessageValue);
      }).finally(() => {
        buttonHelper.enableButton();
      });
  }

  #populateCustomerDetails(customer) {
    this.customerPhoneTarget.value = customer.phone;
    this.customerEmailTarget.value = customer.email;
  }

  #clearCustomerDetails() {
    this.customerPhoneTarget.value = "";
    this.customerEmailTarget.value = "";
  }

  #updateError(message) {
    this.errorContainerTarget.innerHTML = message;
    this.errorContainerTarget.classList.add('d-block');
  }

  #hideError() {
    this.errorContainerTarget.innerHTML = "";
    this.errorContainerTarget.classList.remove('d-block');
  }

  #buttonHelper(button) {
    const oldHtml = button.innerHTML;

    return {
      showLoader: () => {
        button.innerHTML = '<span class="spinner-border spinner-border-sm"></span>';
        button.disabled = true;
      },
      enableButton: () => {
        button.innerHTML = oldHtml;
        button.disabled = false;
      }
    };
  };
}
